// Chakra imports
import { Box, Flex, Text, useColorModeValue, Skeleton } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useEffect, useState, React } from "react";
import BalanceOverviewChart from "components/Charts/BalanceOverviewChart";
import { useAuth } from "auth-context/auth.context";
import DashboardApi from "api/dashboardApi";
import { clientInvestmentsMock } from "variables/charts";

const BalanceOverview = ({ title, chartData }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card
      p="28px 10px 16px 0px"
      mb={{ sm: "26px", lg: "0px" }}
      className={"BalanceOverview"}
    >
      <CardHeader mb="20px" pl="22px">
        <Flex direction="column" alignSelf="flex-start">
          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
            {title}
          </Text>
        </Flex>
      </CardHeader>
      {
        chartData.length === 0 ?
          (
            <Skeleton w="100%" h={{ sm: "300px" }} ps="8px">
            </Skeleton>
          ) :
          (<Box w="100%" h={{ sm: "300px" }} ps="8px">
            <BalanceOverviewChart chartData={chartData} />
          </Box>)
      }
    </Card>
  );
};

export default BalanceOverview;

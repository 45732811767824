import axios from "./index";

class ReportsGenerationApi {
    static downloadDocumentTxnStatement = (start_date, end_date, documentType = 'pdf') => {
        return axios.get(`/clientTransaction/transaction_statement/?start_date=${start_date}&end_date=${end_date}&documentType=${documentType}`,
            {
                headers: this.headers,
                responseType: 'blob',
            }
        );
    };

    static downloadDocumentAccountStatement = (start_date, end_date, documentType = 'pdf') => {
        return axios.get(`/clientTransaction/account_statement/?start_date=${start_date}&end_date=${end_date}&documentType=${documentType}`,
            {
                headers: this.headers,
                responseType: 'blob',
            }
        );
    };
}

export default ReportsGenerationApi;

export const barChartData = [
  {
    name: "Sales",
    data: [330, 250, 110, 300, 490, 350, 270, 130, 425],
  },
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      backgroundColor: "red",
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    show: false,
    labels: {
      show: false,
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "#fff",
    labels: {
      show: true,
      style: {
        colors: "#fff",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
  },
  fill: {
    colors: "#fff",
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "12px",
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

export const lineChartData = [
  {
    name: "Dummy Product",
    data: [[new Date().getTime(), 0]], // Example data point
  },
  {
    name: "Liquid Balance",
    data: [[new Date().getTime(), 0]], // Example data point
  },
];

export const BalanceOverviewChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  stacked: true,
  xaxis: {
    type: "datetime",
    labels: {
      formatter: function (value) {
        return new Date(value).toLocaleDateString("en-US");
      },
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `$${value}`;
      },
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
  },
};

export const BalanceOverviewChartOptions2 = {
  chart: {
    type: "bar",
    stacked: true,
    toolbar: {
      show: false,
    },
    legend: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  tooltip: {
    theme: "dark",
    x: {
      format: "dd MMM yyyy",
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    tickAmount: 1,
    type: "datetime",
    labels: {
      formatter: function (value) {
        return new Date(value).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        });
      },
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    tickAmount: 3,
    labels: {
      formatter: function (value) {
        var val = Math.abs(value);
        if (val >= 1000) {
          val = (val / 1000).toFixed(0) + "k USD";
        }
        return val;
      },
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
  },
};

export const clientInvestmentsMock = {
  success: true,
  results: [
    {
      client_id: 2,
      date: "2015-03-21",
      liquid: {
        funds: 0.0,
      },
      product: [
        {
          name: "product1",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product2",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product3",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
      ],
    },
    {
      client_id: 2,
      date: "2015-03-23",
      liquid: {
        funds: 0.0,
      },
      product: [
        {
          name: "product1",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product2",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product3",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
      ],
    },
    {
      client_id: 2,
      date: "2015-03-24",
      liquid: {
        funds: 0.0,
      },
      product: [
        {
          name: "product1",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product2",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product3",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product4",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product5",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
      ],
    },
    {
      client_id: 2,
      date: "2015-03-25",
      liquid: {
        funds: 0.0,
      },
      product: [
        {
          name: "product1",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product2",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product3",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product4",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
        {
          name: "product5",
          shares: 100 + Math.floor(Math.random() * 51),
          usd: 80 + Math.floor(Math.random() * 71),
        },
      ],
    },
  ],
};

export const clientInvestmentsMock2 = [
  {
    name: "PRODUCT A",
    data: [44, 55, 41, 67, 22, 43],
  },
  {
    name: "PRODUCT B",
    data: [13, 23, 20, 8, 13, 27],
  },
  {
    name: "PRODUCT C",
    data: [11, 17, 15, 15, 21, 14],
  },
  {
    name: "PRODUCT D",
    data: [21, 7, 25, 13, 22, 8],
  },
];

// Chakra imports
import {
    Button,
    Flex,
    Link,
    FormControl,
    Input,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import React, { useState } from "react";
import AuthApi from "../../api/auth";

function ResetPassword() {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState("");
    const [hide, setHide] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

    const titleColor = useColorModeValue("blue.300", "blue.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        setDisabledSubmitButton(true);
        AuthApi.ResetPassword(formData).then(response => {
            if (response.data.success) {
                setHide(true)
                setError(response.data.msg)
            } else {
                setError(response.data.msg)
            }
            setDisabledSubmitButton(false);
        }).catch(error => {
            if (error.response) {
                setError(error.response.data.msg);
            } else {
                setError("There has been an error");
            }
            setDisabledSubmitButton(false);
        })
    }

    return (
        <Flex
            direction='column'
            alignSelf='center'
            justifySelf='center'
            overflow='hidden'>
            <Flex
                direction='column'
                textAlign='center'
                justifyContent='center'
                align='center'
                mt='6.5rem'
                mb='30px'>
            </Flex>
            <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
                <Flex
                    direction='column'
                    w='445px'
                    background='transparent'
                    borderRadius='15px'
                    p='40px'
                    mx={{ base: "100px" }}
                    bg={bgColor}
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                    <Text
                        fontSize='xl'
                        color={textColor}
                        fontWeight='bold'
                        textAlign='center'
                        mb='22px'>
                        Reset Password
                    </Text>
                    <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='bold'
                        textAlign='center'
                        mb='22px'>
                        Enter your Email address. We will send a reset password link to your register email.
                    </Text>
                    <FormControl>
                        <Input
                            hidden={hide}
                            fontSize='sm'
                            ms='4px'
                            borderRadius='15px'
                            type='email'
                            placeholder='Your email address'
                            mb='24px'
                            size='lg'
                            name="email"
                            onChange={handleChange}
                        />
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color="red" marginBottom="15px" fontWeight='medium'>
                                {error}
                            </Text>
                        </Flex>
                        <Button
                            hidden={hide}
                            disabled={disabledSubmitButton}
                            onClick={handleSubmit}
                            type='submit'
                            bg='blue.300'
                            fontSize='10px'
                            color='white'
                            fontWeight='bold'
                            w='100%'
                            h='45'
                            mb='24px'
                            _hover={{
                                bg: "blue.200",
                            }}
                            _active={{
                                bg: "blue.400",
                            }}>
                            Reset
                        </Button>
                    </FormControl>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        maxW='100%'
                        mt='0px'>
                        <Text color={textColor} fontWeight='medium'>
                            Already have an account?
                            <Link
                                color={titleColor}
                                ms='5px'
                                href='#/auth/signin'
                                fontWeight='bold'>
                                Sign In
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>)
}

export default ResetPassword;

// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ReportsGeneration from "./components/ReportsGeneration";
import ClientTransactions from "./components/ClientTransactions";

function Reports() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "100px" }}>
      <ReportsGeneration />
      <ClientTransactions />
    </Flex>
  );
}

export default Reports;

import axios from "./index";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/register/`, data);
  };

  static ResetPassword = (data) => {
    return axios.post(`${base}/reset_password`, data);
  };

  static ChangePassword = (data, token) => {
    return axios.post(`${base}/reset_password/${token}`, data);
  };

  static Logout = (data) => {
    return axios.post(`${base}/logout`, data);
  };

  static UserRegistrationActivation = (uid, token) => {
    return axios.get(`${base}/register/activate/${uid}/${token}`);
  };

  static checkSession = (data) => {
    return axios.post(`${base}/checkSession`, data);
  };

  static verifyOTP = (data) => {
    return axios.post(`${base}/verify-otp`, data);
  };

  static resendOTP = (data) => {
    return axios.post(`${base}/resend-otp`, data);
  };
}

let base = "users";

export default AuthApi;

// Chakra imports
import {
  Flex,
  Grid,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Container,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// assets

// Custom icons
import { useAuth } from 'auth-context/auth.context';
import { GlobeIcon, WalletIcon } from "components/Icons/Icons.js";
import { useState, useEffect, React } from "react";
import MiniStatistics from "./components/MiniStatistics";
import ProductStatistics from "./components/ProductStatistics";
import PerformanceStatistics from "./components/PerformanceStatistics";
import BalanceOverview from "./components/BalanceOverview";
import FundsCube from "./components/FundsCube";
import { useHistory } from "react-router-dom";
import dashboardApi from "api/dashboardApi";
import { productsPlaceholder, productsPdfLinks } from "variables/general";

export default function Dashboard() {
  const { user, setUser } = useAuth();
  const iconBoxInside = useColorModeValue("white", "white");
  const history = useHistory();
  const [amountTotalBalance, setAmountTotalBalance] = useState(0);
  const [amountYTDPerformance, setAmountYTDPerformance] = useState(0);
  const [productInformationLink, setProductInformationLink] = useState(
    productsPdfLinks
  );
  const [product, setProduct] = useState(productsPlaceholder);
  const [fund, setFund] = useState(0);
  const [percentageDif, setPercentageDif] = useState(0);
  const [chartDataResults, setChartDataResults] = useState([]);
  const [renderCharts, setRenderCharts] = useState(false);
  const toast = useToast();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fetchInfo = () => {
    const productsPdfLinksPromise = dashboardApi.productsPdfLinks();

    const dashboardDetailsPromise = dashboardApi.dashboardDetails();

    const balanceOverviewChartPromise = dashboardApi.balanceOverviewChart();

    const mePromise = dashboardApi.meApi();

    return Promise.all([
      productsPdfLinksPromise,
      dashboardDetailsPromise,
      balanceOverviewChartPromise,
      mePromise,
    ]).then((response) => {
      //response[0]
      if (response[0].data.success && response[0].data.results.length !== 0) {
        setProductInformationLink(response[0].data.results);
      }

      //response[1]
      if (response[1].data.success && response[1].data.results.length !== 0) {
        setAmountTotalBalance(response[1].data.results[0].total_balance.toFixed(0));
        setAmountYTDPerformance(response[1].data.results[0].total_investments.toFixed(0));
        setProduct(response[1].data.results[0].products);
        setFund(response[1].data.results[0].liquid_funds);
        setPercentageDif(response[1].data.results[0].average_percentage_diff)
      }

      //response[2]
      let resData = response[2].data;
      const result = [];
      const products = {};

      resData.results.forEach(function (investment) {
        if (!products["liquid_funds"]) {
          products["liquid_funds"] = [];
        }
        if (!products["total_investments"]) {
          products["total_investments"] = [];
        }

        const timestamp = new Date(investment.date).getTime(); // Convert date to timestamp

        // Iterate over each product
        investment.products.forEach((product) => {
          // Find the product in the result array or initialize it if it doesn't exist
          let productEntry = result.find((p) => p.name === product.name);
          if (!productEntry) {
            productEntry = { name: product.name, data: [] };
            result.push(productEntry);
          }

          // Iterate over each portfolio of the current product
          product.portfolios.forEach((portfolio) => {
            // Find the portfolio in the productEntry or initialize it if it doesn't exist
            let portfolioEntry = productEntry.data.find(
              (p) => p.name === portfolio.portfolio_name
            );
            if (!portfolioEntry) {
              portfolioEntry = { name: portfolio.portfolio_name, data: [] };
              productEntry.data.push(portfolioEntry);
            }

            // Append the new data point to the portfolio
            portfolioEntry.data.push([timestamp, parseFloat(portfolio.value)]);
          });
        });

        products["liquid_funds"].push([
          new Date(investment.date).getTime(),
          investment.liquid_funds,
        ]);

        products["total_investments"].push([
          new Date(investment.date).getTime(),
          investment.total_investments,
        ]);
      });

      for (const name in products) {
        result.push({ name, data: products[name] });
      }

      setChartDataResults(result);
      setRenderCharts(true);

      //response[3]
      let user = localStorage.getItem("user");
      // let token = JSON.parse(user).token;
      user = response[3].data;
      // user.token = token;
      user = JSON.stringify(user);
      setUser(user);
      localStorage.setItem("user", user);
      // return history.push("/dashboard");
    });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "100px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
        <MiniStatistics
          title={"TOTAL BALANCE"}
          amount={numberWithCommas(amountTotalBalance)}
          icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <PerformanceStatistics
          title={"YTD PERFORMANCE"}
          percentage={percentageDif.toFixed(0)}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(1, 1fr)"
        my="26px"
        gap="24px"
      >
        <Card minH="83px">
          <CardBody size="sm" w="100%">
            <Container maxW="container.lg">
              <Text fontSize="md">Disclaimer</Text>
              <Text fontSize="sm">
                The charts and prices displayed below are provided for
                indicative purposes and should not be relied upon as accurate or
                definitive. These figures are intended for informational
                purposes only and may not reflect real-time values. Please
                refrain from drawing any conclusions or assessing the
                performance of investment products based solely on this
                information. Should you notice any discrepancies or
                inaccuracies, we encourage you to contact us promptly
              </Text>
            </Container>
          </CardBody>
        </Card>
        <BalanceOverview
          title={"HISTORICAL BALANCE"}
          chartData={chartDataResults.filter(
            (chartData) => chartData.name === "total_investments"
          )}
        />
      </Grid>
      <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing="24px">
        {renderCharts
          ? product.map((row, index) => {
            // Filter chartDataResults for data corresponding to this product
            const matchingChartData = chartDataResults.find(
              (chartData) => chartData.name === row.name
            );
            const matchingChartDataResult = matchingChartData
              ? [matchingChartData.data]
              : [];
            return (
              <ProductStatistics
                key={"product_" + index}
                title={row.name}
                amount={numberWithCommas(row.total_value.toFixed(0))}
                // shares={row.total_shares}
                percentage={row.percentage_diff.toFixed(0)} // This value might need dynamic calculation based on your logic.
                informationLink={productInformationLink[index]}
                chartData={matchingChartDataResult[0]} // Pass only the filtered part of chartDataResults relevant to this product.
              />
            );
          })
          : product.map((row, index) => {
            return (
              <ProductStatistics
                key={"product_" + index}
                title={row.name}
                amount={row.total_usd}
                // shares={row.total_shares}
                percentage={0} // This value might need dynamic calculation based on your logic.
                informationLink={productInformationLink[index]}
                chartData={[]} // Pass only the filtered part of chartDataResults relevant to this product.
              />
            );
          })}

        <FundsCube
          key={"fund"}
          title={"Liquid Balance"}
          amount={fund}
          shares={""}
          percentage={""}
          chartData={chartDataResults.filter(
            (chartData) => chartData.name === "liquid_funds"
          )}
        />
      </SimpleGrid>
    </Flex>
  );
}

import React from "react";
import ReactApexChart from "react-apexcharts";

class BalanceOverviewChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartData = props.chartData;
    this.state = {
      chartData: this.chartData,
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.chartData,
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          legend: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        tooltip: {
          theme: "dark",
          x: {
            format: "dd MMM yyyy",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 12,
          type: "datetime",
          labels: {
            formatter: function (value) {
              return new Date(value).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              });
            },
            style: {
              colors: "#A0AEC0",
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          tickAmount: 3,
          labels: {
            formatter: function (value) {
              var val = Math.abs(value);
              if (val >= 1000) {
                val = (val / 1000).toFixed(0) + "k USD";
              }
              return val;
            },
            style: {
              colors: "#A0AEC0",
              fontSize: "12px",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          strokeDashArray: 5,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [],
          },
        },
      },
    });
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="area"
        width="100%"
        height="100%"
      />
    );
  }
}

export default BalanceOverviewChart;

// import
import Dashboard from "views/Dashboard/Dashboard";
import Reports from "views/Dashboard/Reports";
import Home from "views/Auth/Home";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import NotFound from "views/Auth/NotFound.js";
import ResetPassword from "views/Auth/ResetPassword.js";
import ChangePassword from "views/Auth/ChangePassword.js";
import UserRegistration from "views/Auth/UserRegistration.js";
import UserKYC from "views/Auth/UserKYC.js";
import PlaceOrders from "views/Dashboard/PlaceOrders";
import WithdrawDeposit from "views/Dashboard/WithdrawDeposit";
import { EditIcon, PlusSquareIcon, RepeatClockIcon } from "@chakra-ui/icons";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import ProductPerformance from "views/Dashboard/ProductPerformance";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    protected: true,
  },
  {
    path: "/reports",
    name: "Reporting",
    icon: <StatsIcon color="inherit" />,
    component: Reports,
    layout: "/admin",
    protected: true,
  },
  {
    path: "/placeOrders",
    name: "Place Orders",
    icon: <EditIcon color="inherit" />,
    component: PlaceOrders,
    layout: "/admin",
    protected: true,
  },
  {
    path: "/withdrawDeposit",
    name: "Withdraw Deposit",
    icon: <PlusSquareIcon color="inherit" />,
    component: WithdrawDeposit,
    layout: "/admin",
    protected: true,
  },
  {
    path: "/productPerformance",
    name: "Product Performance",
    icon: <RepeatClockIcon color="inherit" />,
    component: ProductPerformance,
    layout: "/admin",
    protected: true,
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/signin",
      //   name: "Sign In",
      //   icon: <DocumentIcon color="inherit" />,
      //   component: SignIn,
      //   layout: "/auth",
      //   protected: true,
      // },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   icon: <RocketIcon color="inherit" />,
      //   component: SignUp,
      //   layout: "/auth",
      //   protected: true,
      // },
      {
        path: "/home",
        name: "Home",
        icon: <RocketIcon color="inherit" />,
        component: Home,
        layout: "/auth",
        protected: false,
      },
      {
        path: "/notFound",
        name: "Not Found",
        icon: <DocumentIcon color="inherit" />,
        component: NotFound,
        layout: "/auth",
        protected: true,
      },
      {
        path: "/resetPassword",
        name: "Reset Password",
        icon: <EditIcon color="inherit" />,
        component: ResetPassword,
        layout: "/auth",
        protected: false,
      },
      {
        path: "/changePassword",
        name: "Change Password",
        icon: <EditIcon color="inherit" />,
        component: ChangePassword,
        layout: "/auth",
        protected: false
      },
      // {
      //   path: "/userRegistration",
      //   name: "User Registration",
      //   icon: <EditIcon color="inherit" />,
      //   component: UserRegistration,
      //   layout: "/auth",
      //   protected: true,
      // },
      {
        path: "/userKYC",
        name: "KYC",
        icon: <EditIcon color="inherit" />,
        component: UserKYC,
        layout: "/auth",
        protected: true,
      },
    ],
  },
];
export default dashRoutes;

import Axios from "axios";
import { API_SERVER } from "../config/constant";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  credentials: "include",
  headers: { "Content-Type": "application/json" },
});


// Request interceptor
axios.interceptors.request.use(
  async (config) => {
    if (Clerk) {
      const session = await Clerk.session;
      if (session) {
        const token = await session.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;

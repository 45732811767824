// Chakra imports
import {
  Link,
  Box,
  Flex,
  Spacer,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Button,
  Stack,
  Skeleton,
  Heading,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import BalanceOverviewChart from "components/Charts/BalanceOverviewChart";
// Custom components
import { InfoIcon, EditIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

const ProductPerformanceStatistics = ({
  title,
  amount,
  percentage,
  shares,
  informationLink,
  chartData,
}) => {
  const iconBlue = useColorModeValue("blue.300", "blue.300");
  const textColor = useColorModeValue("gray.700", "white");
  let navbarIcon = useColorModeValue("white", "gray.200");
  const bgColor = useColorModeValue("white", "gray.700");
  return (
    <Box
      bg={bgColor}
      borderRadius="15px"
      className="ProductPerformanceStatistics"
      p={10}
    >
      <Heading size="md">{title}</Heading>
      <Stack direction={["column"]} spacing="15px">
        <Box>
          {chartData.length === 0 ? (
            <Skeleton w="100%" h={{ sm: "200px" }}></Skeleton>
          ) : (
            <Box w="100%" h={{ sm: "200px" }}>
              <BalanceOverviewChart chartData={chartData} />
            </Box>
          )}
        </Box>
        <Stack
          spacing={1}
          direction={{
            sm: "column",
            md: "row",
            lg: "row",
          }}
        >
          <Spacer />
          <Link href={informationLink} isExternal>
            <Button
              rightIcon={<InfoIcon />}
              colorScheme="blue"
              isLoading={chartData.length === 0}
            >
              Product Information
            </Button>
          </Link>
          <Link as={ReactRouterLink} to="/admin/placeOrders">
            <Button
              rightIcon={<EditIcon />}
              colorScheme="blue"
              isLoading={chartData.length === 0}
            >
              Place Order
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProductPerformanceStatistics;

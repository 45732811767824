let BACKEND_SERVER = null;
let REACT_APP_CLERK_PUBLISHABLE_KEY = null;
let REACT_APP_CLERK_FRONTEND_API = null;

if (process.env.NODE_ENV === "production") {
  REACT_APP_CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
  REACT_APP_CLERK_FRONTEND_API = process.env.REACT_APP_CLERK_FRONTEND_API;
} else if (process.env.NODE_ENV === "development") {
  REACT_APP_CLERK_PUBLISHABLE_KEY =
    "pk_test_Y2FsbS1oZXJtaXQtMTIuY2xlcmsuYWNjb3VudHMuZGV2JA";
  BACKEND_SERVER = "http://localhost:5000/api/";
  REACT_APP_CLERK_FRONTEND_API = "https://calm-hermit-12.clerk.accounts.dev";
}

export const API_SERVER = BACKEND_SERVER;
export const PUBLISHABLE_KEY = REACT_APP_CLERK_PUBLISHABLE_KEY;
export const CLERK_FRONTEND_API = REACT_APP_CLERK_FRONTEND_API;
export const GENERATE_SOURCEMAP = false;

/*!

=========================================================
* Blue Rabbit - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple
* Modified by Vinay Valson for Blue Rabbit

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { dark, light } from '@clerk/themes';
import { ClerkProvider, ClerkLoaded } from '@clerk/clerk-react';
import { PUBLISHABLE_KEY } from "config/constant";
import { AuthProvider } from "auth-context/auth.context";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

// Import your publishable key
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const App = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} appearance={{
      baseTheme: colorMode === "dark" ? light : dark
    }}>
      <ClerkLoaded>
        <AuthProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Redirect from={`/`} to="/auth/home" />
            </Switch>
          </HashRouter>
        </AuthProvider>
      </ClerkLoaded>
    </ClerkProvider >
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

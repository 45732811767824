// Chakra imports
import {
  Flex,
  FormControl,
  Select,
  Text,
  useColorModeValue,
  FormLabel,
  Button,
  useToast,
  NumberInput,
  Input,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Skeleton,
  Tooltip,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  ButtonGroup,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect } from "react";
import PlaceOrdersApi from "api/placeOrdersApi";
import ReactApexChart from "react-apexcharts";

const BuyProducts = ({ total = 100 }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [allowedCurrencies, setAllowedCurrencies] = useState([]);
  const [allowedProducts, setAllowedProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [orderCurrency, setOrderCurrency] = useState("");
  const [orderAmount, setOrderAmount] = useState(1000);
  const [productValue, setProductValue] = useState(0);
  const [productCatalog, setProductCatalog] = useState();
  const [sliderValues, setSliderValues] = useState();
  const [disable, setDisable] = useState(false);
  const [remarks, setRemarks] = useState(false);
  const [pieChartData, setPieChartData] = useState({ series: [], options: {} });
  const bgColor = useColorModeValue("white", "gray.700");
  const pieChartColor = useColorModeValue("light", "dark");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [transactions, setTransactions] = useState();
  const [placeOrderDisable, setPlaceOrderDisable] = useState(false);

  const handleProductTypeChange = (event) => {
    setProductValue(0);
    setProductType(event.target.value);
    setSliderValues(productCatalog[event.target.value][0].product_percentages);
    handlePieChartValueChange(
      productCatalog[event.target.value][0].product_percentages
    );
  };

  const handleOrderCurrencyChange = (event) => {
    setRemarks(null);
    setOrderCurrency(event.target.value);
  };

  // const handleRemarksChange = (event) => {
  //   if (orderCurrency === "00009") {
  //     setRemarks(event.target.value);
  //   } else {
  //     setRemarks("");
  //   }
  // }

  const handleProductValueChange = (event) => {
    setProductValue(parseInt(event));
    setSliderValues(
      productCatalog[productType][parseInt(event)].product_percentages
    );
    handlePieChartValueChange(
      productCatalog[productType][parseInt(event)].product_percentages
    );
  };

  const handlePieChartValueChange = (sliderValues) => {
    const series = Object.values(sliderValues);
    const options = {
      chart: {
        background: "#00",
        width: "100%",
      },
      stroke: {
        colors: ["#00"],
      },
      theme: {
        mode: pieChartColor,
        palette: "palette10",
        monochrome: {
          enabled: true,
          shadeTo: pieChartColor,
        },
      },
      labels: Object.keys(sliderValues).map((key) => {
        const product = allowedProducts.find(
          (product) => product.product_id === key
        );
        return product ? product.product_name : "Unknown"; // Fallback in case no match is found
      }),
      legend: {
        position: "bottom",
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(0) + "%"];
        },
      },
      responsive: [
        {
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    setPieChartData({ series, options });
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const parse = (val) => val.replace(/^\,/, "");

  // Handler to update slider values
  const handleSliderChange = (value, id) => {
    setProductValue(productCatalog[productType].length - 1);

    let newValue = Math.min(Math.round(value), total); // Round immediately to nearest integer
    let currentTotal =
      Object.values(sliderValues).reduce((acc, cur) => acc + cur, 0) -
      sliderValues[id];
    let overflow = currentTotal + newValue - total;
    // Update slider values
    const updatedSliderValues = {
      ...sliderValues, // Use previous values
      [id]: newValue, // Update the changed value
      // ...adjustOtherSliders(sliderValues, id, overflow), // Get adjustments for other sliders
    };

    setSliderValues(updatedSliderValues); // Set the new state

    // Call the function with the updated values
    handlePieChartValueChange(updatedSliderValues);
  };

  const adjustOtherSliders = (prevValues, changedId, overflow) => {
    const otherIds = Object.keys(prevValues).filter((id) => id !== changedId);
    let adjustment = overflow / otherIds.length;
    let residual = overflow % otherIds.length; // Handle any residual due to integer division
    let updatedValues = {};

    otherIds.forEach((id, index) => {
      let newAdjustment = Math.round(adjustment + (index < residual ? 1 : 0)); // Distribute the residual
      let newValue = prevValues[id] - newAdjustment;
      updatedValues[id] = Math.max(0, Math.min(newValue, total)); // Ensure values are clamped properly
    });

    return updatedValues;
  };

  // This function does the submit order
  const handleSubmitForBuyProducts = (e) => {
    let formData = {
      productType: productType,
      orderCurrency: orderCurrency,
      orderAmount: orderAmount,
      productValue: productValue,
      sliderValues: sliderValues,
    };

    toast.closeAll();

    if (!formData.productType) {
      return toast({
        title: "Error",
        duration: 10000,
        description: "Select Product Type",
        status: "error",
        isClosable: true,
      });
    }

    if (!formData.orderCurrency) {
      return toast({
        title: "Error",
        duration: 10000,
        description: "Select Order Currency",
        status: "error",
        isClosable: true,
      });
    }

    if (!formData.orderAmount) {
      return toast({
        title: "Error",
        duration: 10000,
        description: "Enter Order Amount",
        status: "error",
        isClosable: true,
      });
    }

    if (!formData.orderAmount) {
      return toast({
        title: "Error",
        duration: 10000,
        description: "Enter Order Amount",
        status: "error",
        isClosable: true,
      });
    }

    // if (!formData.remarks && formData.orderCurrency === "00009") {
    //   return toast({
    //     title: "Error",
    //     duration: 10000,
    //     description: "Enter Remarks for Other Type of Currency",
    //     status: "error",
    //     isClosable: true,
    //   });
    // }

    // Calculate the sum of the percentage values from sliderValues
    const totalPercentage = Object.values(formData.sliderValues).reduce(
      (sum, current) => sum + current,
      0
    );

    if (totalPercentage > 100) {
      return toast({
        title: "Warning",
        description:
          "Please readjust the percentages of the products such that they add up to 100%",
        status: "warning",
        isClosable: true,
      });
    }

    // Normalize the slider values if they don't sum to 100
    const normalizedSliderValues = Object.fromEntries(
      Object.entries(formData.sliderValues).map(([key, value]) => [
        key,
        (value / totalPercentage) * 100,
      ])
    );

    // Calculate the transfer amounts based on the normalized percentages
    setTransactions(
      Object.entries(normalizedSliderValues)
        .filter(([product_id, percentage]) => percentage > 0) // Filter out entries with 0 percentage
        .map(([product_id, percentage]) => ({
          transfer_type: "buy", // always 'buy'
          product_id: product_id,
          transfer_currency: formData.orderCurrency,
          transfer_amount: Math.round(
            formData.orderAmount * (percentage / 100)
          ),
        }))
    );

    // Log the transactions or send them to the server
    setDisable(true);
    onOpen(true);
  };

  const callPlaceOrderAPI = (e) => {
    setPlaceOrderDisable(true);
    PlaceOrdersApi.postClientTransaction(transactions)
      .then((response) => {
        setDisable(false);
        setPlaceOrderDisable(false);
        onClose(true);
        return toast({
          title: "Success",
          description: "Your request has been submitted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setDisable(false);
        setPlaceOrderDisable(false);
        if (!error.response.data.success) {
          return toast({
            title: "Error",
            description: error.response.data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      });
  };

  const fetchInfo = () => {
    const getAllowedCurrenciesPromise = PlaceOrdersApi.getAllowedCurrencies(
      "client~client_dep"
    );

    const getAllowedProductsPromise = PlaceOrdersApi.getAllowedProducts();

    const getProductCatelogsPromise = PlaceOrdersApi.getProductCatalogs();

    return Promise.all([
      getAllowedCurrenciesPromise,
      getAllowedProductsPromise,
      getProductCatelogsPromise,
    ])
      .then((response) => {
        //response[0]
        setAllowedCurrencies(response[0].data);

        //response[1]
        setAllowedProducts(response[1].data);

        //response[2]
        setProductCatalog(response[2].data);
        setSliderValues(response[2].data["liquid"][0].product_percentages);
        handlePieChartValueChange(
          response[2].data["liquid"][0].product_percentages
        );
      })
      .catch((error) => {
        setDisable(false);
        return toast({
          title: "Error",
          duration: 10000,
          description: "There has been an error.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleModalWindowClose = () => {
    // Place your additional code here
    setDisable(false);

    // Perform cleanup or other tasks
    // After executing your code, call the original onClose function
    onClose();
  };

  //Call APIs inside this
  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Box bg={bgColor} borderRadius="15px" className="BuyProducts">
      <Box pl={7} pr={7} pt={7}>
        <Heading as="h1" size="md">
          Buy Product
        </Heading>
        <Text fontSize="md">
          Please complete the below instructions to place an order
        </Text>
      </Box>
      <Flex
        flexDirection={{
          sm: "column",
          md: "row",
          lg: "row",
        }}
        w="100%"
      >
        <Box w={["100%", "50%"]} p={5}>
          <FormControl isDisabled={disable}>
            <Box p={2}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Product Type
              </FormLabel>
              <Select
                name="product_type"
                placeholder="Please Select"
                value={productType}
                onChange={handleProductTypeChange}
                disabled={!productCatalog}
              >
                <option value="liquid">Liquid</option>
                <option value="not_liquid">Not Liquid</option>
              </Select>
            </Box>

            {productType &&
              allowedProducts &&
              productCatalog &&
              sliderValues ? (
              // when productType is liquid
              <>
                <Box p={2}>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Order Currency
                  </FormLabel>
                  <Select
                    name="transfer_currency"
                    placeholder="Please Select"
                    onChange={handleOrderCurrencyChange}
                  >
                    {allowedCurrencies.map((row, index) => {
                      return (
                        <option value={row.account_id} key={index}>
                          {row.currency_ticker}
                        </option>
                      );
                    })}
                  </Select>
                </Box>

                {/* {orderCurrency === "00009" ? (
                  <Box p={2}>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Other currencies or digital assets are subject to Blue Rabbit approval
                    </FormLabel>
                    <Input placeholder='Enter remarks' onChange={handleRemarksChange}
                    />
                  </Box>
                ) : (<></>)} */}

                <Box p={2}>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Order Amount
                  </FormLabel>
                  <NumberInput
                    clampValueOnBlur={false}
                    keepWithinRange={false}
                    step={100}
                    min={1000}
                    onChange={(valueString) =>
                      setOrderAmount(parse(valueString))
                    }
                    value={numberWithCommas(orderAmount)}
                  >
                    <NumberInputField id="amount" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>

                <Box p={2}>
                  <RadioGroup
                    onChange={handleProductValueChange}
                    value={productValue}
                  >
                    <Stack direction={["column", "row"]}>
                      {productCatalog &&
                        productCatalog[productType].map((row, index) => {
                          return (
                            <Radio
                              key={index}
                              id={"product_category_" + index}
                              value={row.product_id}
                            >
                              {row.product_name}
                            </Radio>
                          );
                        })}
                    </Stack>
                  </RadioGroup>
                </Box>

                {productCatalog &&
                  Object.keys(
                    productCatalog[productType][productValue]
                      .product_percentages
                  ).map((key, index) => {
                    const productLabelName = allowedProducts.find(
                      (product) => product.product_id === key
                    )?.product_name;
                    return (
                      <Box p={2} key={index}>
                        <FormLabel fontWeight="normal">
                          {productLabelName}
                        </FormLabel>
                        <Flex
                          flexDirection={{
                            sm: "column",
                            md: "row",
                            lg: "row",
                          }}
                          w="100%"
                        >
                          <NumberInput
                            min={0}
                            max={100}
                            maxW="100px"
                            mr="2rem"
                            value={sliderValues[key]}
                            disabled={productType === "not_liquid" || disable}
                            onChange={(val) => handleSliderChange(val, key)}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <Slider
                            disabled={productType === "not_liquid" || disable}
                            aria-label={`slider-ex-${index}`}
                            value={sliderValues[key]}
                            min={0}
                            max={total}
                            onChange={(val) => handleSliderChange(val, key)}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                          >
                            <SliderTrack>
                              <SliderFilledTrack />
                            </SliderTrack>
                            <Tooltip
                              hasArrow
                              bg="blue.500"
                              color="white"
                              placement="bottom"
                              isOpen={showTooltip}
                              label={`${sliderValues[key]}%`}
                            >
                              <SliderThumb />
                            </Tooltip>
                          </Slider>
                        </Flex>
                      </Box>
                    );
                  })}

                <Box p={2}>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    w="100%"
                    disabled={disable}
                    isLoading={disable}
                    onClick={handleSubmitForBuyProducts}
                  >
                    Place Order
                  </Button>
                </Box>
              </>
            ) : (
              <Box p={2}>
                <Skeleton></Skeleton>
              </Box>
            )}
          </FormControl>
        </Box>
        <Box w={["100%", "50%"]} p={5}>
          {productType ? (
            <ReactApexChart
              height="400px"
              type="pie"
              series={pieChartData.series}
              options={pieChartData.options}
            />
          ) : (
            <></>
          )}
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} w={"50%"}>
        <ModalOverlay />
        <ModalContent width={{ base: "90%", md: "500px", lg: "800px" }}>
          <ModalHeader>Buy Order Summary</ModalHeader>
          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Product Name</Th>
                    <Th>Order Amount</Th>
                    <Th>Order Currency</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactions &&
                    transactions.map((key, index) => {
                      const productLabelName = allowedProducts.find(
                        (product) => product.product_id === key.product_id
                      )?.product_name;
                      const productTransferCurrency = allowedCurrencies.find(
                        (currency) =>
                          currency.account_id === key.transfer_currency
                      )?.currency_ticker;
                      return (
                        <Tr key={index}>
                          <Td>{productLabelName}</Td>
                          <Td>{numberWithCommas(key.transfer_amount)}</Td>
                          <Td>{productTransferCurrency}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup gap="1">
              <Button
                colorScheme="blue"
                onClick={callPlaceOrderAPI}
                disabled={placeOrderDisable}
                isLoading={placeOrderDisable}
              >
                Place Order
              </Button>
              <Button
                variant="ghost"
                isLoading={placeOrderDisable}
                onClick={handleModalWindowClose}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BuyProducts;

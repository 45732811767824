import {
    Flex,
    FormControl,
    Select,
    Text,
    useColorModeValue,
    FormLabel,
    Button,
    useToast,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Box,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    ButtonGroup,
    Checkbox,
} from "@chakra-ui/react";
// Custom components
import React, { useState, useEffect } from "react";
import PlaceOrdersApi from "api/placeOrdersApi";
import DashboardApi from "api/dashboardApi";

const SellProducts = ({ total = 100 }) => {
    const [allowedCurrencies, setAllowedCurrencies] = useState([]);
    const [allowedProducts, setAllowedProducts] = useState([]);
    const [productType, setProductType] = useState("");
    const [orderCurrency, setOrderCurrency] = useState("");
    const [orderAmount, setOrderAmount] = useState(1000);
    const [productValue, setProductValue] = useState(0);
    const [sellDataTable, setSellDataTable] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [disable, setDisable] = useState(false);
    const [remarks, setRemarks] = useState(false);
    const bgColor = useColorModeValue("white", "gray.700");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [transactions, setTransactions] = useState([]);
    const [placeOrderDisable, setPlaceOrderDisable] = useState(false);

    const handleProductTypeChange = (event) => {
        setProductValue(0);
        setProductType(event.target.value);
    };

    const handleOrderCurrencyChange = (event) => {
        setRemarks(null);
        setOrderCurrency(event.target.value);
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const parse = (val) => val.replace(/^\,/, "");

    const handleProductSelection = (product_id, isChecked) => {
        setSelectedProducts((prevState) => ({
            ...prevState,
            [product_id]: {
                ...prevState[product_id],
                isChecked: isChecked,
                percentage: isChecked ? prevState[product_id]?.percentage || 1 : 0,
            },
        }));
    };

    const handlePercentageChange = (product_id, percentage) => {
        setSelectedProducts((prevState) => ({
            ...prevState,
            [product_id]: {
                ...prevState[product_id],
                percentage: percentage,
            },
        }));
    };

    const handleSubmitForSellProducts = (e) => {
        let formData = {
            productType: productType,
            orderCurrency: orderCurrency,
            orderAmount: orderAmount,
            productValue: productValue,
        };

        toast.closeAll();

        if (!formData.orderCurrency) {
            return toast({
                title: "Error",
                duration: 10000,
                description: "Select Order Currency",
                status: "error",
                isClosable: true,
            });
        }

        let transactionData = [];
        for (const product_id in selectedProducts) {
            if (selectedProducts[product_id].isChecked) {
                const product = sellDataTable.find(p => p.product_id === product_id);
                const transfer_amount = parseFloat((product.total_value * selectedProducts[product_id].percentage) / 100).toFixed(2);
                transactionData.push({
                    product_id: product_id,
                    product_name: product.name, // Include product name
                    transfer_currency: orderCurrency,
                    transfer_type: "sell",
                    transfer_amount: (transfer_amount),
                });
            }
        }

        setTransactions(transactionData);

        if (transactionData.length === 0) {
            return toast({
                title: "Error",
                duration: 10000,
                description: "Select at least one product and specify the percentage",
                status: "error",
                isClosable: true,
            });
        }

        setDisable(true);
        onOpen(true);
    };

    const callPlaceOrderAPI = (e) => {
        setPlaceOrderDisable(true);
        console.log(transactions);
        PlaceOrdersApi.postClientTransaction(transactions)
            .then((response) => {
                setDisable(false);
                setPlaceOrderDisable(false);
                onClose(true);
                return toast({
                    title: "Success",
                    description: "Your request has been submitted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setDisable(false);
                setPlaceOrderDisable(false);
                if (!error.response.data.success) {
                    return toast({
                        title: "Error",
                        description: error.response.data.msg,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            });
    };

    const fetchInfo = () => {
        const getAllowedCurrenciesPromise = PlaceOrdersApi.getAllowedCurrencies(
            "client~client_dep"
        );

        const getClientCurrentPositions = DashboardApi.dashboardDetails();

        return Promise.all([
            getAllowedCurrenciesPromise,
            getClientCurrentPositions
        ])
            .then((response) => {
                setAllowedCurrencies(response[0].data);

                setSellDataTable(response[1].data.results[0].products.map(product => ({
                    name: product.name,
                    shares: product.shares.toFixed(2),
                    product_id: product.product_id,
                    total_value: product.total_value.toFixed(2)
                })));
            })
            .catch((error) => {
                setDisable(false);
                return toast({
                    title: "Error",
                    duration: 10000,
                    description: "There has been an error.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const handleModalWindowClose = () => {
        setDisable(false);
        onClose();
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <Box bg={bgColor} borderRadius="15px" className="SellProducts">
            <Box pl={7} pr={7} pt={7}>
                <Heading as="h1" size="md">
                    Sell Product
                </Heading>
                <Text fontSize="md">
                    Please complete the below instructions to place an order
                </Text>
            </Box>
            <Flex flexDirection={{ sm: "column", md: "row", lg: "row" }} w="100%">
                <Box w={"100%"} p={5}>
                    <FormControl isDisabled={disable}>
                        <Box p={2} w={{ sm: "100%", md: "100%", lg: "50%" }}>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                Order Currency
                            </FormLabel>
                            <Select
                                name="transfer_currency"
                                placeholder="Please Select"
                                onChange={handleOrderCurrencyChange}
                            >
                                {allowedCurrencies.map((row, index) => {
                                    return (
                                        <option value={row.account_id} key={index}>
                                            {row.currency_ticker}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Box>

                        {sellDataTable.length > 0 && (
                            <Box p={2}>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                    Products
                                </FormLabel>
                                <TableContainer>
                                    <Table variant="simple" colorScheme={bgColor}>
                                        <Thead>
                                            <Tr>
                                                <Th>Select</Th>
                                                <Th>Name</Th>
                                                <Th>Shares</Th>
                                                {/* <Th>Product ID</Th> */}
                                                <Th>Total Value</Th>
                                                <Th minW={"200px"}>Percentage</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {sellDataTable.map((product, index) => (
                                                <Tr key={index}>
                                                    <Td>
                                                        <Checkbox
                                                            isChecked={selectedProducts[product.product_id]?.isChecked || false}
                                                            isDisabled={product.shares <= 0}
                                                            onChange={(e) => handleProductSelection(product.product_id, e.target.checked)}
                                                        />
                                                    </Td>
                                                    <Td>{product.name}</Td>
                                                    <Td>{product.shares}</Td>
                                                    {/* <Td>{product.product_id}</Td> */}
                                                    <Td>{product.total_value}</Td>
                                                    <Td >
                                                        <Flex
                                                            flexDirection={{
                                                                sm: "column",
                                                                md: "column",
                                                                lg: "column",
                                                            }}
                                                            w="100%"
                                                        >
                                                            <Box w={"100%"} p={5}>
                                                                <NumberInput
                                                                    value={selectedProducts[product.product_id]?.percentage || 0}
                                                                    onChange={(value) => handlePercentageChange(product.product_id, value)}
                                                                    min={1}
                                                                    max={100}
                                                                    step={1}
                                                                    isDisabled={!selectedProducts[product.product_id]?.isChecked}
                                                                >
                                                                    <NumberInputField />
                                                                    <NumberInputStepper>
                                                                        <NumberIncrementStepper />
                                                                        <NumberDecrementStepper />
                                                                    </NumberInputStepper>
                                                                </NumberInput>
                                                            </Box>
                                                            <Slider
                                                                min={1}
                                                                max={100}
                                                                step={1}
                                                                value={selectedProducts[product.product_id]?.percentage || 0}
                                                                onChange={(value) => handlePercentageChange(product.product_id, value)}
                                                                isDisabled={!selectedProducts[product.product_id]?.isChecked}
                                                            >
                                                                <SliderTrack >
                                                                    <SliderFilledTrack />
                                                                </SliderTrack>
                                                                <SliderThumb />
                                                            </Slider>

                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        <Box p={2}>
                            <Button
                                colorScheme="blue"
                                type="submit"
                                w="100%"
                                disabled={disable}
                                isLoading={disable}
                                onClick={handleSubmitForSellProducts}
                            >
                                Place Order
                            </Button>
                        </Box>
                    </FormControl>
                </Box>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose} w={"50%"}>
                <ModalOverlay />
                <ModalContent width={{ base: "90%", md: "500px", lg: "800px" }}>
                    <ModalHeader>Sell Order Summary</ModalHeader>
                    <ModalBody>
                        <TableContainer>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Product Name</Th>
                                        <Th>Order Amount</Th>
                                        <Th>Order Currency</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {transactions &&
                                        transactions.map((key, index) => {
                                            const productLabelName = allowedProducts.find(
                                                (product) => product.product_id === key.product_id
                                            )?.product_name;
                                            const productTransferCurrency = allowedCurrencies.find(
                                                (currency) =>
                                                    currency.account_id === key.transfer_currency
                                            )?.currency_ticker;
                                            return (
                                                <Tr key={index}>
                                                    <Td>{key.product_name}</Td>
                                                    <Td>{numberWithCommas(key.transfer_amount)}</Td>
                                                    <Td>{productTransferCurrency}</Td>
                                                </Tr>
                                            );
                                        })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup gap="1">
                            <Button
                                colorScheme="blue"
                                onClick={callPlaceOrderAPI}
                                disabled={placeOrderDisable}
                                isLoading={placeOrderDisable}
                            >
                                Place Order
                            </Button>
                            <Button
                                variant="ghost"
                                isLoading={placeOrderDisable}
                                onClick={handleModalWindowClose}
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default SellProducts;

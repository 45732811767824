// Chakra imports
import {
  Wrap,
  WrapItem,
  Link,
  Box,
  Flex,
  Spacer,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Button,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import BalanceOverviewChart from "components/Charts/BalanceOverviewChart";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { InfoIcon, AddIcon, EditIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

const FundsCube = ({
  title,
  amount,
  percentage,
  shares,
  informationLink,
  chartData,
}) => {
  const iconBlue = useColorModeValue("blue.300", "blue.300");
  const textColor = useColorModeValue("gray.700", "white");
  let navbarIcon = useColorModeValue("white", "gray.200");
  return (
    <Card>
      <CardBody>
        <Wrap w="100%" justify="center" align="center">
          <Spacer display={{ base: "none", md: "flex", lg: "flex" }} />

          <WrapItem w="180px">
            <StatGroup>
              <Stat me="auto">
                <StatLabel color={textColor}>{title}</StatLabel>
                <Flex>
                  <StatNumber color={textColor}>USD {amount}</StatNumber>
                </Flex>
              </Stat>
            </StatGroup>
          </WrapItem>

          <WrapItem w="180px">
            <Spacer />
            <Stack spacing={1} direction="column">
              <Link as={ReactRouterLink} to="/admin/placeOrders">
                <Button
                  w="100%"
                  rightIcon={<EditIcon />}
                  colorScheme="blue"
                  isLoading={chartData.length === 0}
                >
                  Place Order
                </Button>
              </Link>
            </Stack>
          </WrapItem>
          <Spacer display={{ base: "none", md: "flex", lg: "flex" }} />

          <WrapItem w="500px">
            {chartData.length === 0 ? (
              <Skeleton w="100%" h={{ sm: "200px" }} ps="8px"></Skeleton>
            ) : (
              <Box w="100%" h={{ sm: "200px" }} ps="8px">
                <BalanceOverviewChart chartData={chartData} />
              </Box>
            )}
          </WrapItem>
        </Wrap>
      </CardBody>
    </Card>
  );
};

export default FundsCube;

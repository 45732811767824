import {
    Box,
    Heading,
    Flex,
    useColorModeValue,
    Button,
    Spacer,
    Image,
    useToast
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import signInImage from "../../assets/img/signInImage.png";
import { useAuth } from 'auth-context/auth.context';
import { React, useEffect } from "react";
import dashboardApi from "api/dashboardApi"
import {
    useUser,
    SignInButton,
} from "@clerk/clerk-react";

function Home() {
    const history = useHistory();
    const { user, setUser } = useAuth();
    const { clerkUser, isSignedIn } = useUser()
    const toast = useToast()

    const redirectToDasboard = () => {
        history.push("/admin/dashboard")
    }

    const fetchInfo = () => {
        const maxRetries = 5;
        const pomodoroIntervals = [1000, 2000, 3000, 4000, 5000]; // Example intervals in milliseconds

        function retryMeApi(attempt = 0) {
            return dashboardApi.meApi()
                .then((response) => {
                    let user = response.data;
                    user = JSON.stringify(user);
                    setUser(user);
                    localStorage.setItem("user", user);
                    redirectToDasboard();
                })
                .catch((error) => {
                    if (attempt < maxRetries) {
                        console.log(`Retry attempt ${attempt + 1} failed. Retrying in ${pomodoroIntervals[attempt]}ms...`);
                        return new Promise((resolve) => setTimeout(resolve, pomodoroIntervals[attempt]))
                            .then(() => retryMeApi(attempt + 1));
                    } else {
                        throw error;
                    }
                });
        }

        retryMeApi();
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    const titleColor = useColorModeValue("blue.300", "blue.200");
    const bgColor = useColorModeValue("gray.200", "gray.700");
    return (
        <Flex position='relative' mb='40px'>
            <Flex
                h={{ sm: "initial", md: "75vh", lg: "85vh" }}
                w='100%'
                mx='auto'
                pt={"100px"}>
                <Spacer />
                <Box
                    bgColor={bgColor}
                    maxW="400px"
                    maxH="520px"
                    h="100%"
                    w="100%"
                    m="20px"
                    borderRadius="20px"
                >
                    <Flex mx='auto'>
                        <Spacer />
                        <Image src={signInImage} alt='logo'
                            m="auto" />
                        <Spacer />
                    </Flex>
                    <Flex mx='auto'>
                        <Spacer />
                        <SignInButton isLoading={isSignedIn} signUpForceRedirectUrl={"/#/auth/home"} mode={"modal"}><Button colorScheme='blue' size='lg'>Access Investor Portal</Button></SignInButton>
                        <Spacer />
                    </Flex>
                </Box>
                <Spacer />
            </Flex>
        </Flex >)
}

export default Home;

// Chakra Icons
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box,
  Button,
  Stack,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import {
  useUser,
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/clerk-react";
import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import { MdOutlineSpaceDashboard, MdOutlineLogin } from "react-icons/md";



export default function HeaderLinks(props) {
  const { clerkUser, isSignedIn } = useUser()
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { user, setUser } = useAuth();
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  const redirectToDasboard = () => {
    history.push("/admin/dashboard")
  }
  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  return (
    <Stack direction="row" spacing={2} alignItems={"center"}>
      {isSignedIn ? (
        <Button display={{ base: 'none', md: 'inherit', lg: 'inherit' }}
          leftIcon={<MdOutlineSpaceDashboard />}
          size="md"
          colorScheme="white"
          variant="ghost"
          onClick={redirectToDasboard}
        >
          Dashboard
        </Button>) : (
        <></>
      )}
      <IconButton
        size="md"
        colorScheme="white"
        variant="ghost"
        onClick={toggleColorMode}
        icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
      >
      </IconButton>
      <SignedOut>
        <SignInButton mode={"modal"} signUpForceRedirectUrl={"/#/admin/dashboard"} >
          <Button size="md" colorScheme="white" variant="ghost">Sign In</Button>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <UserButton>
          <Button size="md" colorScheme="white" variant="ghost"></Button>
        </UserButton>
      </SignedIn>
    </Stack>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

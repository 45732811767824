import axios from "./index";

class PlaceOrdersApi {
  static getUserTermsAndConditions = (data) => {
    return axios.get(`/userTermsAndConditions`);
  };

  static postUserTermsAndConditions = (data) => {
    return axios.post(`/userTermsAndConditions`);
  };

  static getConfigValue = (prop_id, data) => {
    return axios.get(`/config_all_o?prop_id=${prop_id}`);
  };

  static postClientTransaction = (data) => {
    return axios.post(`/clientTransaction/`, data);
  };

  static getAllowedCurrencies = (identifier) => {
    return axios.get(`/allowed_currencies?identifier=${identifier}`);
  };

  static getAllowedProducts = () => {
    return axios.get(`/allowed_products`);
  };

  static startedKyc = () => {
    return axios.get(`/kycStart`);
  };

  static getProductCatalogs = () => {
    return axios.get(`/product_catalogs`);
  };
}

export default PlaceOrdersApi;

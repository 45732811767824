// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Td,
  Thead,
  Tr,
  useColorModeValue,
  Heading,
  SkeletonText,
  Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useState, useEffect } from "react";
import DashboardApi from "api/dashboardApi";
import { useAuth } from "auth-context/auth.context";
import { MdCancel, MdCancelScheduleSend, MdCheck } from "react-icons/md";

const ClientTransactions = ({ title, tableHeaders }) => {
  const { user, setUser } = useAuth();
  const [data, setData] = useState();

  title = "Client Transactions";
  tableHeaders = [
    "transaction id",
    "transfer type",
    "product id",
    "transfer currency",
    "transfer amount",
    "usd value",
    // "fx rate",
    "order date",
    "execution date",
    "created by",
    "created at",
    "updated by",
    "updated at",
    "Status",
  ];

  const fetchInfo = () => {
    DashboardApi.clientTransaction(user).then((res) =>
      setData(res.data.results)
    );
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card>
      <CardHeader p="6px 0px 22px 0px">
        <Heading as="h1" size="md" color={textColor} fontWeight="bold">
          {title}
        </Heading>
      </CardHeader>
      <CardBody overflowX={{ sm: "scroll" }}>
        <Table size="sm" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableHeaders.map((header, index) => {
                return (
                  <Th key={"header_key_" + index} color="gray.400">
                    {header}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data
              ? data.map((row, index) => {
                  return (
                    <Tr key={"row_" + index}>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.transaction_id}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.transfer_type}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.product_id ? row.product_id.product_name : ""}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.transfer_currency
                            ? row.transfer_currency.currency_ticker
                            : ""}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.transfer_amount}
                        </Text>
                      </Td>
                      {/*<Td>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      {row.fx_rate}
                    </Text>
                  </Td> */}
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.usd_value}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {new Date(row.order_date).toLocaleDateString("en-US")}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.execution_date ? (
                            new Date(row.execution_date).toLocaleDateString(
                              "en-US"
                            )
                          ) : (
                            <MdCancelScheduleSend color="red" />
                          )}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.created_by}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {new Date(row.created_at).toLocaleDateString("en-US")}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.updated_by}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {new Date(row.updated_at).toLocaleDateString("en-US")}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="sm"
                          color="gray.400"
                          fontWeight="normal"
                        >
                          {row.status === "T" ? (
                            <Button colorScheme="red" size="xs">
                              Terminated
                            </Button>
                          ) : (
                            <></>
                          )}
                          {row.status === "C" ? (
                            <Button colorScheme="green" size="xs">
                              Completed
                            </Button>
                          ) : (
                            <></>
                          )}
                          {row.status === "P" ? (
                            <Button colorScheme="teal" size="xs">
                              Pending
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Text>
                      </Td>
                    </Tr>
                  );
                })
              : Array.from({ length: 7 }).map((_, index) => (
                  <Tr key={"row_" + index}>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                    <Td>
                      <SkeletonText noOfLines={1} />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ClientTransactions;

// Chakra imports
import {
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import React, { useState, useEffect } from "react";
import AuthApi from "../../api/auth";
import {
    useLocation, useHistory
} from "react-router-dom";
import NotFound from "./NotFound";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function UserRegistration() {
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const [error, setError] = useState("")
    let query = useQuery();
    let token = query.get("token");
    let uid = query.get("uid");


    const fetchInfo = () => {
        AuthApi.UserRegistrationActivation(uid, token).then(response => {
            if (response.data.success) {
                return setError(response.data.msg)
            } else {
                return setError(response.data.msg)
            }
        }).catch(error => {
            if (error.response) {
                return setError(response.data.msg)
            } else {
                return setError(response.data.msg)
            }
        })

    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        token && uid ?
            <Flex
                direction='column'
                alignSelf='center'
                justifySelf='center'
                overflow='hidden'>
                <Flex
                    direction='column'
                    textAlign='center'
                    justifyContent='center'
                    align='center'
                    mt='6.5rem'
                    mb='30px'>
                </Flex>
                <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
                    <Flex
                        direction='column'
                        w='445px'
                        background='transparent'
                        borderRadius='15px'
                        p='40px'
                        mx={{ base: "100px" }}
                        bg={bgColor}
                        boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                        <Text
                            fontSize='xl'
                            color={textColor}
                            fontWeight='bold'
                            textAlign='center'
                            mb='22px'>
                            User Registration
                        </Text>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color="red" marginBottom="15px" fontWeight='medium'>
                                {error}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            : <>
                <NotFound></NotFound>
            </>)
}

export default UserRegistration;

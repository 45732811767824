// Chakra imports
import {
  Flex,
  Grid,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Container,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";

// assets

// Custom icons
import { useState, useEffect, React } from "react";
import dashboardApi from "api/dashboardApi";
import ProductPerformanceStatistics from "./components/ProductPerformanceStatistics";
import { productsPlaceholder, productsPdfLinks } from "variables/general";

export default function ProductPerformance() {
  const toast = useToast();
  const [chartDataResults, setChartDataResults] = useState([]);
  const [renderCharts, setRenderCharts] = useState();
  const [productInformationLink, setProductInformationLink] = useState(
    productsPdfLinks
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fetchInfo = () => {
    const productPerformancePromise = dashboardApi.productPerformance();
    const productsPdfLinksPromise = dashboardApi.productsPdfLinks();

    return Promise.all([productsPdfLinksPromise, productPerformancePromise])
      .then((response) => {
        //response[0]
        if (response[0].data.success && response[0].data.results.length !== 0) {
          setProductInformationLink(response[0].data.results);
        }
        //response[1]
        const result = [];
        response[1].data.results.forEach((investment) => {
          const timestamp = new Date(investment.date).getTime();
          Object.values(investment.products).forEach((product) => {
            if (product.name) {
              // Find the product in the result array or initialize it if it doesn't exist
              let productEntry = result.find((p) => p.name === product.name);
              if (!productEntry) {
                productEntry = {
                  name: product.name,
                  data: [],
                };
                result.push(productEntry);
              }

              // Append the new data point to the portfolio
              productEntry.data.push([timestamp, parseFloat(product.nav)]);
            }
          });
        });

        setChartDataResults(result);
        setRenderCharts(true);
      })
      .catch((error) => {
        return toast({
          title: "Error",
          description: "There has been an error.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Box>
      <Flex flexDirection="column" pt={{ base: "120px", md: "100px" }}>
        <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing="24px">
          {renderCharts
            ? chartDataResults.map((row, index) => {
              // Filter chartDataResults for data corresponding to this product
              return (
                <ProductPerformanceStatistics
                  key={"product_" + index}
                  title={row.name}
                  amount={"test"}
                  // shares={row.total_shares}
                  percentage={55} // This value might need dynamic calculation based on your logic.
                  informationLink={productInformationLink[index]}
                  chartData={[row]} // Pass only the filtered part of chartDataResults relevant to this product.
                />
              );
            })
            : Array.from({ length: 5 }).map((_, index) => (
              <ProductPerformanceStatistics
                key={"empty_product_" + index}
                title={""}
                amount={""}
                percentage={55} // This value might need dynamic calculation based on your logic
                informationLink={""}
                chartData={[]} // Empty data array
              />
            ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
}

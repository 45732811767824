// Chakra imports
import { Text, useColorModeValue, Flex } from "@chakra-ui/react";
import placeOrdersApi from "api/placeOrdersApi";
// Assets
import React, { useEffect } from "react";

function UserKYC() {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "white");

  const fetchInfo = () => {
    placeOrdersApi.startedKyc();
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      ></Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="row"
          background="transparent"
          borderRadius="15px"
          p="20px"
          height="1200px"
          display="flex"
          width="60%"
          bg={bgColor}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.cognitoforms.com/f/97z4Fbt-pEa6FxuP7AH9eg/1"
          ></iframe>
          <script src="https://www.cognitoforms.com/f/iframe.js"></script>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UserKYC;

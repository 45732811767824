// Chakra imports
import {
    Input,
    useColorModeValue,
    Heading,
    Stack,
    Button,
    Radio,
    RadioGroup,
    useToast,
    FormLabel
} from "@chakra-ui/react";
import { DownloadIcon } from '@chakra-ui/icons'
// Custom components
import React, { useState, useEffect } from "react";
import ReportsGenerationApi from "api/reportsGenerationApi";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

const ReportsGeneration = () => {
    let todayDate = new Date().toISOString().substring(0, 10);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [apiCallComplete, setApiCallComplete] = useState(false);
    const [reportType, setReportType] = useState('pdf')
    const toast = useToast();

    function handleStartDateChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setStartDate(value);
    }

    function handleEndDateChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setEndDate(value);
    }

    function SubmitDownloadDocumentAccountStatement(e) {
        e.preventDefault();
        if (!startDate || !endDate) {
            return toast({
                title: "Error",
                description: "Select a start and end date",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        else if (new Date(startDate) > new Date(endDate)) {
            return toast({
                title: "Error",
                description: "End date cannot be before start date",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        setApiCallComplete(true)
        ReportsGenerationApi.downloadDocumentAccountStatement(startDate, endDate, reportType).then((response) => {
            window.open(URL.createObjectURL(response.data));
            setApiCallComplete(false)
        }).catch((error) => {
            setApiCallComplete(false)
            return toast({
                title: "Error",
                description: "There has been an error.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    function SubmitDownloadDocumentTxnStatement(e) {
        e.preventDefault();
        if (!startDate || !endDate) {
            return toast({
                title: "Error",
                description: "Select a start and end date",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        else if (new Date(startDate) > new Date(endDate)) {
            return toast({
                title: "Error",
                description: "End date cannot be before start date",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        setApiCallComplete(true)
        ReportsGenerationApi.downloadDocumentTxnStatement(startDate, endDate, reportType).then((response) => {
            window.open(URL.createObjectURL(response.data));
            setApiCallComplete(false)
        }).catch((error) => {
            setApiCallComplete(false)
            return toast({
                title: "Error",
                description: "There has been an error.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const textColor = useColorModeValue("gray.700", "white");
    return (
        <Card mb={10} maxW={"400px"}>
            <CardHeader p="6px 0px 22px 0px">
                <Heading as="h1" size="md" color={textColor} fontWeight="bold">
                    {"Report Statements"}
                </Heading>
            </CardHeader>
            <CardBody overflowX={{ sm: "scroll" }} w={"100%"}>
                <Stack spacing={4} w={"100%"}>
                    <FormLabel>Start Date</FormLabel>
                    <Input type='date' name="startDate" placeholder='Start Date' max={endDate} onChange={handleStartDateChange} />
                    <FormLabel>End Date</FormLabel>
                    <Input type='date' name="endDate" placeholder='End Date' max={todayDate} onChange={handleEndDateChange} />
                    <FormLabel>File Type</FormLabel>
                    <RadioGroup onChange={setReportType} value={reportType}>
                        <Stack direction='row'>
                            <Radio value='pdf'>PDF</Radio>
                            <Radio value='csv'>CSV</Radio>
                        </Stack>
                    </RadioGroup>
                    <Button isLoading={apiCallComplete} onClick={SubmitDownloadDocumentTxnStatement} rightIcon={<DownloadIcon />}>Download Transaction Statement</Button>
                    <Button isLoading={apiCallComplete} onClick={SubmitDownloadDocumentAccountStatement} rightIcon={<DownloadIcon />}>Download Account Statement</Button>
                </Stack>
            </CardBody>
        </Card >
    );
};

export default ReportsGeneration;
